<template>
	<section class="overview">
		<h2>Überblick</h2>

		<BaseButton
			class="button-secondary"
			:click="onClick"
		>
			{{ (toggle) ? 'Tabelle' : 'Karten' }}
		</BaseButton>

		<AppCards
			v-if="toggle"
			:items="visibleItems"
		/>
		<AppTable
			v-else
			:items="visibleItems"
		/>

		<template v-if="items.length > limit">
			<BasePaginationProgress
				:max="items.length"
				:value="limit"
			/>

			<button
				class="button button-primary more"
				@click="more"
			>
				<span>Mehr anzeigen</span>
			</button>
		</template>
	</section>
</template>

<script>
import {MoreMixin, ToggleMixin} from '../mixins';


export default {
	name: 'SectionOverview',
	components: {
		AppCards: () => import(/* webpackChunkName: "AppCards" */ '@/components/AppCards'),
		AppTable: () => import(/* webpackChunkName: "AppTable" */ '@/components/AppTable'),
	},
	mixins: [MoreMixin, ToggleMixin],
	computed: {
		items() {
			return this.$store.getters.getAnimalsByErathem();
		}
	}
};
</script>

<style lang="scss">
	.overview {
		position: relative;
		z-index: 1;
		background-color: $color-light;
		text-align: center;

		.more {
			margin: 1rem 0 2rem;
		}

		> .button-secondary {
			margin: 1rem 0 3rem;
		}
	}
</style>
